import React, { useState } from 'react';
import { MEMBERSHIPS } from '../data/siteConstants';
import TrialButton from '../components/TrialButton';
const PricingComponent = ({
  title,
  monthlySubTitle,
  monthlyPrice,
  annualPrice,
  annualLink,
  annualSubTitle,
  monthlyLink,
  showAnnualPrice,
  dayPass,
  comingSoon,
  joiningFee,
  saveAnnual,
  hourly = true,
}) => {
  const displayPrice = showAnnualPrice ? annualPrice : monthlyPrice;
  const monthlyOrAnnual = showAnnualPrice ? 'year' : 'mo';
  const link = showAnnualPrice ? annualLink : monthlyLink;
  const subTitle = showAnnualPrice ? annualSubTitle : monthlySubTitle;

  const handlePricingClick = () => {
    window.location.href = link;
  };

  const subPartFigure = showAnnualPrice ? saveAnnual : joiningFee;
  const subPartCopy = showAnnualPrice ? 'save ' : '+ joining fee ';
  return (
    <div
      role="listitem"
      onClick={handlePricingClick}
      className="relative z-30 p-8 my-6 rounded-lg shadow cursor-pointer bg-gray-50 hover:shadow-slate-200 hover:shadow-lg hover:bg-gray-100"
    >
      {comingSoon && (
        <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-full h-full text-center bg-gray-200 rounded-lg opacity-80">
          <h1 className="text-3xl font-bi lg:text-5xl">COMING APRIL 2022</h1>
        </div>
      )}
      <div className="items-center justify-between md:flex">
        <h2 className="text-2xl font-semibold leading-6 text-gray-800">
          {title}
        </h2>
        <div className="flex flex-col md:items-end">
          <p className="mt-4 text-2xl font-semibold leading-6 text-gray-800 md:mt-0">
            {displayPrice}
            {hourly && (
              <>
                {dayPass ? (
                  <span className="text-base font-normal">/hour</span>
                ) : (
                  <span className="text-base font-normal">
                    /{monthlyOrAnnual}
                  </span>
                )}
              </>
            )}
          </p>
          {joiningFee && (
            <p className="mt-0 text-lg font-semibold leading-6 md:mt-0 text-gray-800/60">
              <span className="text-base font-normal">{subPartCopy}</span>
              {subPartFigure}
            </p>
          )}
        </div>
      </div>
      <p className="mt-4 text-base leading-6 text-gray-600 md:w-80">
        {subTitle}
      </p>
    </div>
  );
};

export default function Pricing() {
  const [showAnnualPrice, setShowAnnualPrice] = useState(false);

  const handlePriceToggleClick = () => {
    setShowAnnualPrice(!showAnnualPrice);
  };

  const activeToggleClassName =
    'bg-slate-700 focus:outline-none text-base leading-none text-white font-b rounded-full py-4 px-7';

  const inActiveToggleClassName =
    'bg-gray-100 focus:outline-none text-base leading-none text-black font-r rounded-full py-4 px-6';

  const annualClassName = showAnnualPrice
    ? activeToggleClassName
    : inActiveToggleClassName;

  const monthlyClassName = showAnnualPrice
    ? inActiveToggleClassName
    : activeToggleClassName;
  return (
    <>
      <div className="px-6 py-20 mt-4 xl:mx-auto xl:container 2xl:px-0">
        <div className="items-center justify-between lg:flex">
          <div className="w-full lg:w-1/2">
            <p className="text-base leading-4 text-gray-600">
              Choose your plan
            </p>
            <h1 className="mt-3 text-3xl font-bold leading-10 text-gray-800 md:text-5xl">
              Our membership options
            </h1>
            <p
              role="contentinfo"
              className="hidden mt-5 text-base leading-5 text-gray-600 sm:flex"
            >
              Please select a membership option from below to get started.
            </p>
            <div className="flex flex-col w-56 space-y-6">
              <div
                onClick={handlePriceToggleClick}
                className="flex items-center mt-10 bg-gray-100 rounded-full shadow"
              >
                <button className={monthlyClassName} id="monthly">
                  Monthly
                </button>
                <button className={annualClassName} id="annually">
                  Annually
                </button>
              </div>
              <TrialButton />
            </div>
          </div>
          <div
            className="relative w-full mt-12 xl:w-1/2 lg:w-7/12 lg:mt-0 md:px-8"
            role="list"
          >
            {MEMBERSHIPS.map((membership, index) => {
              return (
                <PricingComponent
                  key={index}
                  title={membership.title}
                  monthlyPrice={membership.monthlyPrice}
                  annualPrice={membership.annualPrice}
                  showAnnualPrice={showAnnualPrice}
                  dayPass={membership.dayPass}
                  monthlyLink={membership.monthlyLink}
                  annualLink={membership.annualLink}
                  monthlySubTitle={membership.monthlySubTitle}
                  annualSubTitle={membership.annualSubTitle}
                  comingSoon={membership.comingSoon}
                  joiningFee={membership.joiningFee}
                  saveAnnual={membership.saveAnnual}
                  hourly={membership.hourly}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
